
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  computed,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { validate as validateUuid } from 'uuid';

import PrimeVueMessage from 'primevue/message';

import type {
  ErrorsMap,
  Countries,
  Region,
  Regions,
  Languages,
  Platform,
  Platforms,
  Browsers,
  Carriers,
  Projects,
  Users,
  Endpoints,
  QualityPresets,
  Campaign,
  Creatives,
} from '@/types';

import {
  TRAFFIC_TYPE_POP,
  TRAFFIC_TYPE_PUSH,
  TRAFFIC_TYPE_IN_PAGE_PUSH,
  TRAFFIC_TYPE_NATIVE,
  TRAFFIC_TYPES,
  TRAFFIC_CATEGORY_MAINSTREAM,
  TRAFFIC_CATEGORIES,
  OPENRTB_DEVICE_TYPE_MOBILE_TABLET,
  OPENRTB_DEVICE_TYPE_PERSONAL_COMPUTER,
  TARGETING_TYPE_DISABLED,
  TARGETING_TYPES,
  TARGETING_TYPES_BY_TRAFFIC_SOURCE,
  TARGETING_TYPES_BY_TRAFFIC_CATEGORY,
  TARGETING_TYPES_BY_SUBSCRIBER_AGE,
  ENTRIES,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_AFFILIATE,
  CREATIVE_TYPE_PUSH_FAMILY,
  CREATIVE_TYPE_NATIVE,
  CAMPAIGN_PAYMENT_TYPE_CPC,
  CAMPAIGN_PAYMENT_TYPE_CPA,
  CAMPAIGN_PAYMENT_TYPES,
  CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_FAST,
  CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES,
  CAMPAIGN_CONNECTION_TYPE_ALL,
  CAMPAIGN_CONNECTION_TYPES,
  CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL,
  CAMPAIGN_APPROVAL_STATUSES,
  CAMPAIGN_STATUS_DISABLED,
  CAMPAIGN_STATUSES,
  CAMPAIGN_DEFAULT_REVSHARE,
  CAMPAIGN_DEFAULT_MAX_CLICKS_PER_IP,
  CAMPAIGN_MIN_BID,
  CAMPAIGN_TOO_HIGH_BID_POP,
  CAMPAIGN_CREATIVE_TYPE_BUILTIN,
  CAMPAIGN_CREATIVE_TYPE_MULTIPLE,
  CAMPAIGN_CREATIVE_TYPES,
  CREATIVE_IMAGE_ASPECT_RATIO_W1H1,
  CREATIVE_IMAGE_ASPECT_RATIO_W4H3,
  CREATIVE_IMAGE_ASPECT_RATIO_W1D97H1,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useEntriesNext from '@/composable/useEntries@next';

import ImageBase64Field from '@/components/ImageBase64Field.vue';

export default defineComponent({
  components: {
    PrimeVueMessage,
    ImageBase64Field,
    Macros: defineAsyncComponent(() => import('@/components/Macros.vue')),
    CustomBids: defineAsyncComponent(() => import('@/components/CustomBids.vue')),
    Creatives: defineAsyncComponent(() => import('@/components/Creatives.vue')),
    TargetingByValues: defineAsyncComponent(() => import('@/components/TargetingByValues.vue')),
    FieldImageBase64Cropper: defineAsyncComponent(() => import('@/components/FieldImageBase64Cropper.vue')),
    FieldImageBase64CropperMultiple: defineAsyncComponent(() => import('@/components/FieldImageBase64CropperMultiple.vue')),
    ScheduleByHours: defineAsyncComponent(() => import('@/components/ScheduleByHours.vue')),
    ScheduleByDays: defineAsyncComponent(() => import('@/components/ScheduleByDays.vue')),
    AdSecure: defineAsyncComponent(() => import('@/views/AdSecure.vue')),
  },
  setup() {
    const i18n = useI18n();

    const { activeUser: user } = useUser();

    const { getEntryByRoute } = useEntries();

    const trafficTypes = [...TRAFFIC_TYPES].filter((option) => [TRAFFIC_TYPE_POP, TRAFFIC_TYPE_PUSH, TRAFFIC_TYPE_IN_PAGE_PUSH, TRAFFIC_TYPE_NATIVE].includes(option.value as number));

    // TODO: Удалить после тестов, разрешить всем
    trafficTypes.forEach((option) => {
      if (option.value === TRAFFIC_TYPE_NATIVE && !user.isSuperAdmin) {
        // eslint-disable-next-line no-param-reassign
        option.disabled = true;
      }
    });

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries', ENTRIES);

    const {
      entries: regions,
      entriesLoading: regionsLoading,
      fetchEntries: fetchRegions,
    } = useEntriesNext<Regions>('/api/region/getRegions', ENTRIES);

    const {
      entries: languages,
      entriesLoading: languagesLoading,
      fetchEntries: fetchLanguages,
    } = useEntriesNext<Languages>('/api/language/getLanguages', ENTRIES);

    const {
      entries: platforms,
      entriesLoading: platformsLoading,
      fetchEntries: fetchPlatforms,
    } = useEntriesNext<Platforms>('/api/platform/getPlatforms', ENTRIES);

    const {
      entries: browsers,
      entriesLoading: browsersLoading,
      fetchEntries: fetchBrowsers,
    } = useEntriesNext<Browsers>('/api/browser/getBrowsers', ENTRIES);

    const {
      entries: carriers,
      entriesLoading: carriersLoading,
      fetchEntries: fetchCarriers,
    } = useEntriesNext<Carriers>('/api/carrier/getCarriers', ENTRIES);

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const {
      entries: managers,
      entriesLoading: managersLoading,
      fetchEntries: fetchManagers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints', ENTRIES);

    const {
      entries: qualityPresets,
      entriesLoading: qualityPresetsLoading,
      fetchEntries: fetchQualityPresets,
    } = useEntriesNext<QualityPresets>('/api/quality-preset/getQualityPresets', ENTRIES);

    const {
      entries: creatives,
      entriesLoading: creativesLoading,
      fetchEntries: fetchCreatives,
    } = useEntriesNext<Creatives>('/api/creative/getCreatives', ENTRIES);

    const minCpc = computed((): number => (user.isStaff() ? 0 : CAMPAIGN_MIN_BID));

    const minCpm = computed((): number => minCpc.value * 1000);

    const campaign = reactive<Campaign>({
      traffic_type: TRAFFIC_TYPE_POP,
      mix_traffic_type: true,
      traffic_category: TRAFFIC_CATEGORY_MAINSTREAM,

      targeting_by_traffic_source: TARGETING_TYPE_DISABLED,
      targeting_by_traffic_category: TARGETING_TYPE_DISABLED,
      targeting_by_countries: TARGETING_TYPE_DISABLED,
      countries_ids: [],
      targeting_by_regions_ids: TARGETING_TYPE_DISABLED,
      regions_ids: [],
      targeting_by_languages_codes: TARGETING_TYPE_DISABLED,
      languages_codes: [],
      targeting_by_platforms: TARGETING_TYPE_DISABLED,
      platforms_ids: [],
      targeting_by_browsers: TARGETING_TYPE_DISABLED,
      browsers_ids: [],
      targeting_by_subscriber_age: TARGETING_TYPE_DISABLED,
      connection_type: CAMPAIGN_CONNECTION_TYPE_ALL,
      targeting_by_ips: TARGETING_TYPE_DISABLED,
      ips: '',
      targeting_by_carriers_ids: TARGETING_TYPE_DISABLED,
      carriers_ids: [],
      targeting_by_endpoints_ids: TARGETING_TYPE_DISABLED,
      endpoints_ids: [],
      targeting_by_endpoints_uids: TARGETING_TYPE_DISABLED,
      endpoints_uids: [],
      targeting_by_referrers_hosts: TARGETING_TYPE_DISABLED,
      referrers_hosts: [],
      targeting_by_subids: TARGETING_TYPE_DISABLED,
      subids_uuids: [],
      targeting_by_hours: TARGETING_TYPE_DISABLED,
      hours: [],
      targeting_by_days: TARGETING_TYPE_DISABLED,
      days: [],
      use_quality_presets: false,
      quality_presets_ids: [],

      creative_type: CAMPAIGN_CREATIVE_TYPE_MULTIPLE,
      creatives: [],

      payment_type: CAMPAIGN_PAYMENT_TYPE_CPC,
      bid: minCpc.value,
      bids_by_countries_ids: [],
      bids_by_endpoints_uids: [],
      bids_by_subids_uuids: [],
      hardcoded_conversion_payout: null,
      revshare: CAMPAIGN_DEFAULT_REVSHARE,

      max_spending: null,
      max_spending_per_day: null,
      max_spending_per_endpoint_per_day: null,
      max_spending_per_day_strategy: CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_FAST,

      test_max_spending: null,
      test_max_spending_per_endpoint: null,
      test_max_spending_per_subid: null,

      max_clicks_per_ip: CAMPAIGN_DEFAULT_MAX_CLICKS_PER_IP,

      filter_ip_mismatch: false,
      filter_user_agent_mismatch: false,
      filter_referrer_mismatch: false,
      filter_timezone_mismatch: false,
      filter_proxy: true,
      filter_has_no_js: false,
      filter_iframe: false,
      filter_headless: false,
      filter_double_click: false,
      filter_click_without_impression: false,

      is_builtin: false,
      meta_refresh: true,

      adsecure: true,
      has_violations: false,

      approval_status: CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL,
      status: CAMPAIGN_STATUS_DISABLED,
    });

    const campaignErrorsMap = ref<ErrorsMap>({});

    const isPush = computed((): boolean => [TRAFFIC_TYPE_PUSH, TRAFFIC_TYPE_IN_PAGE_PUSH].includes(campaign.traffic_type as number));

    const isNative = computed((): boolean => campaign.traffic_type === TRAFFIC_TYPE_NATIVE);

    const creativeTypeFilter = computed((): number|null => {
      if (isPush.value) {
        return CREATIVE_TYPE_PUSH_FAMILY;
      }

      if (isNative.value) {
        return CREATIVE_TYPE_NATIVE;
      }

      return null;
    });

    const macros = computed((): string[] => {
      // Общие макросы для всех типов трафика
      const macrosCommon = [
        'click_uuid',
        'bid_cpc',
        'bid_cpm',
        'zone_uid',
        'subid_uuid',
        'campaign_id',
        'country_alpha2_code',
        'city',
        'carrier',
        'platform_name',
        'browser_name',
        'creative_id',
      ];

      // Макросы для Push и In-Page Push кампаний
      if (isPush.value) {
        macrosCommon.push(...[
          'subscriber_uuid',
          'subscriber_age_date',
          'subscriber_age_timestamp',
          'subscriber_age_days',
          'creative_id',
        ]);
      }

      return macrosCommon;
    });

    const fetchRegionsWrapper = async (): Promise<void> => fetchRegions({
      country_id: campaign.countries_ids,
    });

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? campaign.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const fetchManagersWrapper = async (): Promise<void> => fetchManagers({
      project_id: user.isSuperAdmin() ? campaign.project_id : null,
      type: [
        USER_TYPE_ADMIN,
        USER_TYPE_MANAGER,
      ],
    });

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      project_id: user.isSuperAdmin() ? campaign.project_id : null,
    });

    const fetchQualityPresetsWrapper = async (): Promise<void> => fetchQualityPresets({
      project_id: user.isSuperAdmin() ? campaign.project_id : null,
    });

    const fetchCreativesWrapper = async (): Promise<void> => fetchCreatives({
      project_id: user.isSuperAdmin() ? campaign.project_id : null,
      user_id: user.isStaff() ? campaign.user_id : null,
      type: creativeTypeFilter.value,
    });

    const fetchCampaign = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/campaign/getCampaignById');

      if (entry) {
        Object.assign(campaign, entry);
      }

      Promise.all([
        fetchCountries(),
        campaign.countries_ids?.length ? fetchRegionsWrapper() : Promise.resolve(),
        fetchLanguages(),
        fetchPlatforms(),
        fetchBrowsers(),
        fetchCarriers(),
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isStaff() ? fetchUsersWrapper() : Promise.resolve(),
        user.isStaff() ? fetchManagersWrapper() : Promise.resolve(),
        user.isStaff() ? fetchEndpointsWrapper() : Promise.resolve(),
        user.isStaff() ? fetchQualityPresetsWrapper() : Promise.resolve(),
        fetchCreativesWrapper(),
      ]);
    };

    const storeCampaign = async (): Promise<void> => {
      const response = await axios.post('/api/campaign/storeCampaign', campaign);

      Object.assign(campaign, response.data);
    };

    const cloneCampaign = (): void => {
      campaign.id = undefined;

      campaign.name += ` - ${i18n.t('copy_female')}`;
    };

    const onUserChange = (): void => {
      campaign.creatives = [];

      fetchCreativesWrapper();
    };

    const onProjectChange = (): void => {
      campaign.user_id = undefined;

      fetchUsersWrapper();

      campaign.manager_id = undefined;

      fetchManagersWrapper();

      campaign.quality_presets_ids = [];

      fetchEndpointsWrapper();

      fetchQualityPresetsWrapper();

      onUserChange();
    };

    const onTrafficTypeChange = (): void => {
      if (isPush.value || isNative.value) {
        campaign.payment_type = CAMPAIGN_PAYMENT_TYPE_CPC;

        campaign.creatives = [];

        fetchCreativesWrapper();
      }

      // Для Push кампаний такие фильтры не нужны
      if (campaign.traffic_type === TRAFFIC_TYPE_PUSH) {
        campaign.filter_ip_mismatch = false;

        campaign.filter_user_agent_mismatch = false;
      }
    };

    const onCountriesIdsChange = async (): Promise<void> => {
      if (!campaign.countries_ids?.length) {
        campaign.targeting_by_regions_ids = TARGETING_TYPE_DISABLED;

        campaign.regions_ids = [];

        return;
      }

      await fetchRegionsWrapper();

      // Убираем регионы стран, которые больше не выбраны в списке

      campaign.regions_ids = campaign.regions_ids?.filter((regionId) => regions.data.map((region: Region) => region.id).includes(regionId));
    };

    const selectDesktopPlatforms = (): void => {
      campaign.platforms_ids = platforms.data
        .filter((platform: Platform) => platform.device_type === OPENRTB_DEVICE_TYPE_PERSONAL_COMPUTER)
        .map((platform: Platform) => platform.id);
    };

    const selectMobilePlatforms = (): void => {
      campaign.platforms_ids = platforms.data
        .filter((platform: Platform) => platform.device_type === OPENRTB_DEVICE_TYPE_MOBILE_TABLET)
        .map((platform: Platform) => platform.id);
    };

    return {
      TRAFFIC_TYPE_POP,
      TRAFFIC_TYPE_PUSH,
      TRAFFIC_TYPE_NATIVE,
      TRAFFIC_CATEGORIES,
      TARGETING_TYPE_DISABLED,
      TARGETING_TYPES,
      TARGETING_TYPES_BY_TRAFFIC_SOURCE,
      TARGETING_TYPES_BY_TRAFFIC_CATEGORY,
      TARGETING_TYPES_BY_SUBSCRIBER_AGE,
      CAMPAIGN_PAYMENT_TYPE_CPC,
      CAMPAIGN_PAYMENT_TYPE_CPA,
      CAMPAIGN_PAYMENT_TYPES,
      CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_FAST,
      CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES,
      CAMPAIGN_CONNECTION_TYPES,
      CAMPAIGN_APPROVAL_STATUSES,
      CAMPAIGN_STATUSES,
      CAMPAIGN_TOO_HIGH_BID_POP,
      CAMPAIGN_CREATIVE_TYPE_BUILTIN,
      CAMPAIGN_CREATIVE_TYPE_MULTIPLE,
      CAMPAIGN_CREATIVE_TYPES,
      CREATIVE_IMAGE_ASPECT_RATIO_W1H1,
      CREATIVE_IMAGE_ASPECT_RATIO_W4H3,
      CREATIVE_IMAGE_ASPECT_RATIO_W1D97H1,
      user,
      trafficTypes,
      countriesLoading,
      countries,
      regionsLoading,
      regions,
      languagesLoading,
      languages,
      platformsLoading,
      platforms,
      browsersLoading,
      browsers,
      carriersLoading,
      carriers,
      projectsLoading,
      projects,
      usersLoading,
      users,
      managersLoading,
      managers,
      endpointsLoading,
      endpoints,
      qualityPresetsLoading,
      qualityPresets,
      creativesLoading,
      creatives,
      minCpc,
      minCpm,
      campaign,
      campaignErrorsMap,
      isPush,
      isNative,
      macros,
      validateUuid,
      fetchCampaign,
      storeCampaign,
      cloneCampaign,
      onProjectChange,
      onUserChange,
      onTrafficTypeChange,
      onCountriesIdsChange,
      selectDesktopPlatforms,
      selectMobilePlatforms,
    };
  },
});
